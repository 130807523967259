@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sen:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&family=Sen:wght@700&display=swap);
body {
  background-color: #F4F4F4;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Nunito', 'Sen', 'Rubik'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


  .overall{
    background-color: transparent;
  }
  .hero-container{
    display: flex;
    background-color: transparent;
    align-items: center;
    padding-left: 5rem;
    padding-right: 5rem;
    font-weight: 800;
    margin: 2rem;
   
  }

  .hero-container-left{
    display: flex;
    flex-direction: column;
    flex: 1 1;
 
    font-weight: 800;
  }
  .hero-container-right{
    display: flex;
    align-items: center;
    position: relative;
    justify-content:center;
    flex: 1 1;
    width: 100%;
    font-family: "Sen, sans-serif";
    font-weight: 800;
  }
  .image-right{
    width: 100%;
  }

  .title-left{
    font-family: "Sen, sans-serif";
    font-weight: 600;
    line-height: 1.1;
    font-weight: 800;
   }

   .span-left{
    position: relative;
    font-weight: 800;
    font-family: "Sen, sans-serif" !important;
    
   }
   .span-left2{
    
    font-weight: 800;
    color: #6159e8;
    font-family: "Sen, sans-serif";
    
  }
  .headline-title{
    
    font-family: "Sen, sans-serif";
  }
  .button-container{
    display: flex;
    opacity: 1;
-webkit-transform: translateZ(0);
        transform: translateZ(0);
  }
  .getstarted-button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    width: auto;
    line-height: 1.2;
    border-radius:9999px;
    font-weight: 700;
    transition-duration: 200ms;
    height: 3rem;
    min-width: 3rem;
    font-size: 1.25rem;
    line-height: 1.2;
    color: #FFFFFF;
    background-color: #6159e8;
    -webkit-padding-start: 1.5rem;
            padding-inline-start: 1.5rem;
    -webkit-padding-end: 1.5rem;
            padding-inline-end: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: "Sen, sans-serif";
   cursor: pointer;
  }
  .btn-filled-orange {
    color:white;   
    background-color: transparent;
    outline:0;
    background-color: #6159e8;
    font-family: 'Ubuntu', sans-serif;
    border:0;
    border-radius: 20px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 800;
}

.btn-filled-orange a{
  color:white;   
  
}

.btn-filled-orange a:hover{
  color:black;   
  
}
  .text-gsbutton{
    display: inline-flex; 
    align-self: center;
    flex-shrink: 0;
  }
  .illustration{
top: 0;
left: 0;
bottom: 0;
right: 0;
box-sizing: border-box;
padding: 0;
border: none;
margin: auto;
display: block;
width: 522px;
height: 522px;
min-width: 100%;
max-width: 100%;
min-height: 100%;
max-height: 100%;
  }

  @media (max-width: 70em){
    .hero-container{
      flex-direction: row;
      padding-top: 3rem;
      padding-bottom: 3rem;
      padding-left: 0;
  
      margin: none;
    }
    .hero-container-left{
      margin-top: 0px;
    }
   .title-left{
    font-size: 3.75rem;
    text-align: start;
   }
.span-left{
  text-align: start;
  font-size: 48px;
}
.span-left2{

  font-size: 50px;
}

.illustration{

  display: none;
  
    }


  }
.subheader-container{
   
    background-color: #C5E4F3;
    width: 80%;
    padding: 3rem;
    -webkit-margin-start: auto;
            margin-inline-start: auto;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
   text-align: center;
    border-radius: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.subheader-title{
font-size: 36px;
font-weight: 800;
font-family: 'Ubuntu', sans-serif;
}

@media (max-width: 480px) {
    .subheader-container {
      width: 90%;
      padding: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    .subheader-title {
      font-size: 20px;
    }
  }
.container-content{
    background-color: #efefef;
    display: grid;
    margin-top: 1rem;
    margin-bottom: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 1.5rem;
    width: 80%;
    padding: 3rem;
    -webkit-margin-start: auto;
            margin-inline-start: auto;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
    text-align: center;
    border-radius: 1rem;
    
}
.mini-container1{
  border: 1px solid black;
    border-radius: 12px;
    background-color: #C5E4F3;
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  

}
.css-9aafwz{
  margin-top: 0.5rem;
}


.description {
  font-size: 14px;
  text-align: center;
  padding: 0 16px;
}

.css-1regj17 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading-card{
  font-weight: 600;
  font-family: 'Sen', sans-serif;

}


.btn-card {
  color:white;   
  background-color: transparent;
  outline:0;
  background-color: #6159e8;
  font-family: 'Ubuntu', sans-serif;
  border:0;
  border-radius: 18px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 0.9rem;
  cursor: pointer;
  font-weight: 800;
  margin-bottom: 14px;
  
}


.btn-card a{
  color:white;   
  
}

.btn-card a:hover{
  color:black; 
  
}

.badge{
  font-size: 12px;
  color: black !important;
 
}

.category-badge{
  font-size: 14px;
  color: black !important;
  background-color: white;
  border-radius: 18px;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-weight: 500;
  margin-right: 0.2rem;
}

.container-image{
width: 100%;
height: 220px;
position: relative;
overflow: hidden;
border-top-left-radius: 0.75rem;
border-top-right-radius: 0.75rem;
object-fit: cover;
}
.thumbnail{
    width: 100%;
    height: 220px;
    object-fit: cover;
}


@media only screen and (max-width: 600px) {
    .container-content {
      width: 100%;
      padding: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .button-container-card {
      margin-top: 0rem; /* Add negative margin to the button container */
    }
    .css-9aafwz{
      margin-top: 0.5rem;
      margin-bottom: -0.5rem;
    }
  }

  @media (max-width: 480px) {
    .container-image {
      height: 150px;
    }
    .thumbnail{
       
        height: 150px;
    }
  }
.blog-container{
    background-color: #efefef;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 80%;
    padding: 3rem;
    -webkit-margin-start: auto;
            margin-inline-start: auto;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
    
    border-radius: 1rem;
    font-weight: 500;
  
}
.main-title{
    text-align: center;
    margin-bottom: 3rem;
}

.code-box {
    font-weight: 400;
    padding: 20px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #888;
    box-shadow: 1px 1px 1px #999;
    font-size: 1rem;
    letter-spacing: 1px;
    /* resize: none; */
    color: #fff;
    background-color: #383737;
    font-family: 'Inconsolata', monospace;
    box-shadow: 10px 10px 5px -12px rgba(0,0,0,0.75);
    }

    @media screen and (max-width: 600px) {
  .code-box {
    font-size: 0.8rem;
    padding: 10px;
  }
  @media only screen and (max-width: 480px) {
    /* Style adjustments for small screens go here */
    .blog-container {
      width: 100%; /* Full width on small screens */
      padding: 1rem; /* Smaller padding on small screens */
      border-radius: 0.5rem;
    }
  }
  
  /* For devices with a width larger than 480px */
  @media only screen and (min-width: 480px) {
    /* Style adjustments for large screens go here */
    .blog-container {
      width: 80%; /* Original width for large screens */
      padding: 3rem;
      border-radius: 0.5rem; /* Original padding for large screens */
    }
  }


}

.table-title{
  color: black;
  font-weight: 800;
}
.table-left{
  color: black;
  font-weight: 600;
}
.table-right{
  color: black;
  font-weight: 600;
}
.code-text{
  color: red;
  
}
.footer {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
 
}


.left-link {
  margin-right: auto;
 
}
.footer-links{
 text-decoration: none;
  color: #000000;
}
.right-link {
  margin-left: auto;
}

table.mytable {
  border-collapse: collapse;
  border: 2px solid black;
}
div.image-div{
  margin: 10px;

}
.image-div {
  text-align: center;
 
}

.image-post {
  width: 60%;
  height: auto;
  border: 1px solid #000000;
  border-radius: 4px;
  margin-bottom: 12px;
}

.image-post {
  width: 60%;
  height: auto;
  border: 1px solid #000000;
  border-radius: 4px;
  margin-bottom: 12px;
  
}


.image-post-chapter2 {
  width: 40%;
  height: auto;
  border: 1px solid #000000;
  border-radius: 4px;
  margin-bottom: 12px;
  
}

.image-post-chapter3 {
  width: 30%;
  height: auto;
  border: 1px solid #000000;
  border-radius: 4px;
  margin-bottom: 12px;
  
}



@media only screen and (max-width: 600px) {
  .image-post {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
  }
  .image-post-chapter2 {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}
}

.container-table-contents {
    background-color: #efefef;
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    margin-bottom: 1rem;
    width: 80%;
    padding: 3rem;
    -webkit-margin-start: auto;
            margin-inline-start: auto;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
    text-align: center;
    border-radius: 1rem;
    font-weight: 500;
  }

  .containerr {
  
    padding: 20px;
    border-radius: 12px;
  }
  .singlet{
    padding-left: 50px;
  }
  
.logos-companies{
  width: 120px;
  height: 100px;
  display: inline-block;
  margin: 10px;
}

.logos-companies2{
  width: 120px;
  height: 60px;
  display: inline-block;
  margin: 10px;
}
.logos-companies3{
  width: 90px;
  height: 90px;
 margin: 10px; 
 display: inline-block;
  
}

.logos-companies4{
  width: 75px;
  height: 75px;
 margin: 10px; 
 display: inline-block;
  
}

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;

    padding: 12px;
  }
  
  .badge {
    margin-bottom: 20px;
  }
  
  .badge {
    display: inline-block;
    background-color: #fff;
    color: #333;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 20px;
  }
  
  .description-title {
    font-size: 1rem;
    line-height: 1.5;
  
  }
  
  @media (max-width: 768px) {
    .container-table-contents {
      flex-direction: column;
    }
  
    .row {
      flex-direction: column;
    }

    .description-title {
      display: none;
    
    }
    .logos-companies{
      display: none;
    }
    
    .logos-companies2{
      display: none;
    }
    .logos-companies3{
      display: none;
    }
    .singlet{
      padding: 0px;
    }
  }
  
  .row {
    text-align: start;
    line-height: 2rem;
    flex: 1 1;
  }
