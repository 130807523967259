.container-table-contents {
    background-color: #efefef;
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    margin-bottom: 1rem;
    width: 80%;
    padding: 3rem;
    margin-inline-start: auto;
    margin-inline-end: auto;
    text-align: center;
    border-radius: 1rem;
    font-weight: 500;
  }

  .containerr {
  
    padding: 20px;
    border-radius: 12px;
  }
  .singlet{
    padding-left: 50px;
  }
  
.logos-companies{
  width: 120px;
  height: 100px;
  display: inline-block;
  margin: 10px;
}

.logos-companies2{
  width: 120px;
  height: 60px;
  display: inline-block;
  margin: 10px;
}
.logos-companies3{
  width: 90px;
  height: 90px;
 margin: 10px; 
 display: inline-block;
  
}

.logos-companies4{
  width: 75px;
  height: 75px;
 margin: 10px; 
 display: inline-block;
  
}

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;

    padding: 12px;
  }
  
  .badge {
    margin-bottom: 20px;
  }
  
  .badge {
    display: inline-block;
    background-color: #fff;
    color: #333;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 20px;
  }
  
  .description-title {
    font-size: 1rem;
    line-height: 1.5;
  
  }
  
  @media (max-width: 768px) {
    .container-table-contents {
      flex-direction: column;
    }
  
    .row {
      flex-direction: column;
    }

    .description-title {
      display: none;
    
    }
    .logos-companies{
      display: none;
    }
    
    .logos-companies2{
      display: none;
    }
    .logos-companies3{
      display: none;
    }
    .singlet{
      padding: 0px;
    }
  }
  
  .row {
    text-align: start;
    line-height: 2rem;
    flex: 1;
  }